import React, {Fragment, useEffect, useState} from 'react';

import FloatPicWebLocation from '../../components/features/floatpic-web-location';
import TopHeader from '../../components/snippets/top-header';

import {useOrder} from '../../contexts/OrderContext';
import {usePage} from '../../contexts/PageContext';

import {COUNTRY_DATA} from '../../enums/country';
import {
    getSubdomain,
} from '../../utils/common';

import 'swiper/css';
import './Home.View.scss';
import Slides from "../../components/features/home/slides";
import TempImages from "../../components/features/home/TempImages.View";

const HomeView = () => {
    const [isfloatPicWebLocation, setIsfloatPicWebLocation] = useState(true);
    const {
        setPriceDetails,
        setLoadingPrice,
    } = useOrder();

    const {
        showing,
        floatPictures,
        country,
        setCountry,
        filteredFloatPictures,
        loading,
        setLoading,
        isAddToCartOpen,
        reset,
        idFromUrl
    } = usePage();

    const initSubdomain = async () => {
        setLoading(true)
        let subdomain = null;
        try {
            subdomain = await getSubdomain();
        } catch (error) {
            subdomain = null;
        } finally {
            setLoading(false);
        }

        if (subdomain) {
            setIsfloatPicWebLocation(COUNTRY_DATA[subdomain]?.host !== subdomain); // TODO:: Uncomment if you want to show the location selection page in Subdomain sites
            if (subdomain === 'us') {
                setCountry('us');
            } else if (subdomain === 'ca') {
                setCountry('ca');
            } else if (subdomain === 'uk' || subdomain === 'jade-cobbler-ef9658') {
                setCountry('uk');
            } else if (subdomain === 'au') {
                setCountry('au');
            } else {
                setCountry('us');
            }
        }
    }

    const handelInit = () => {
        if (!idFromUrl) return;
        setIsfloatPicWebLocation(!idFromUrl)
    }

    const loadPriceDetails = async () => {
        setLoadingPrice(true);
        try {
            const response = await fetch(
                `https://floatpic-staging-rcg7asssaa-uc.a.run.app/product_pricing/?${country && country !== 'us' ? 'country=' + country : ''}`
            );
            const {price_list, currency_code, currency_sign} = await response.json();
            const [S40_price, S55_price, S70_price] = price_list;
            setPriceDetails({
                S40_price,
                S55_price,
                S70_price,
                currency_code,
                currency_sign,
            });
        } catch (err) {
            console.log(err);
        }
        setLoadingPrice(false);
    }

    useEffect(() => {
        if (window.location.pathname === '/') {
            reset();
        }
    }, [window.location]);

    useEffect(() => {
        initSubdomain();
        handelInit();
    }, []);

    // useEffect(() => {
    //     if ((currentSlide === null || !idFromUrl) && showing === 'allProducts') {
    //         if (floatPictures.length) {
    //             setCurrentSlide(floatPictures[0].art_id);
    //         }
    //     }
    //     setFloatPictures(floatPictures);
    //     setStopReload(false);
    // }, [floatPictures]);
    //
    //

    useEffect(() => {
        (async () => {
            try {
                await loadPriceDetails();
            } catch (err) {
                throw new Error(err);
            }
        })();
    }, [country]);

    useEffect(() => {
        if (!isfloatPicWebLocation && showing === 'home') {
            document.title = `FloatPic ${COUNTRY_DATA[country]?.name} Official Website | The ultimate physical representation of a still image for wall display`;
        }
    }, [isfloatPicWebLocation, showing])

    return (
        <Fragment>
            {loading ? (
                <div className='ripple-container'>
                    <div className='loadingio-spinner-ripple-jce4u9nidk'>
                        <div className='ldio-i8dcdsg1jeb'>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ) : (
                <Fragment>
                    {isfloatPicWebLocation ? (
                        <FloatPicWebLocation
                            setIsfloatPicWebLocation={setIsfloatPicWebLocation}
                        />
                    ) : (
                        <div className={isAddToCartOpen ? 'overlay' : ''}>
                            <div className='home-container'>
                                <TopHeader/>

                                {showing === 'home' ? (
                                    <video
                                        className='frist-video'
                                        loop
                                        autoPlay="autoplay"
                                        muted
                                        playsInline=''
                                        preload='auto'
                                        id='index_video'
                                        src={'/images/video.webm'}
                                        poster={'/images/fallbackimage.jpg'}
                                    >
                                        <source
                                            src='/images/video.mp4'
                                            type='video/mp4'
                                        />
                                        <source
                                            src='/images/video.webm'
                                            type='video/webm'
                                        />
                                    </video>
                                ) : <></>}

                                <Slides/>
                            </div>

                            {(floatPictures.length || filteredFloatPictures.length) ? (
                                <TempImages/>
                            ) : <></>}
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};
export default HomeView;
