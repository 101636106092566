import {Row} from 'react-bootstrap';
import {usePage} from '../../../contexts/PageContext';
import CookieCondition from '../cookie-condition/CookieCondition.View';
import './FloatPicWebLocation.View.scss';
import {COUNTRY_DATA} from '../../../enums/country';
import React, {Fragment, memo} from "react";

const FloatPicWebLocation = memo(({setIsfloatPicWebLocation}) => {
    const {setCountry} = usePage();

    const handelSelect = (country) => {
        if (COUNTRY_DATA[country]?.siteUrl && window.location.href !== COUNTRY_DATA[country]?.siteUrl) {
            window.location.assign(COUNTRY_DATA[country]?.siteUrl);
        } else {
            setCountry('us');
            setIsfloatPicWebLocation(false); // TODO:: Remove this line after testing
        }
    }

    // useEffect(() => {
    //     document.title = `FloatPic Official Website | Select Your Country/Region`;
    // }, [])

    return (
        <div className='floatpic-web-location-container container-fluid '>
            <Row>
                <div className='inner-location-container'>
                    <div className='img-container'>
                        <img src='/images/splash-logo1.png'
                             loading="lazy" alt='Floatpic Home'/>
                    </div>
                    <Fragment>
                        <p className='location-header'>
                            Select a location to continue to FloatPic.com
                        </p>
                        <ul className='location-list'>
                            <li className='location-list-item'>
                                    <span className='location-list-item-link' onClick={() => handelSelect('us')}>
                                        United States
                                    </span>
                            </li>
                            <li className='location-list-item'>
                                    <span className='location-list-item-link' onClick={() => handelSelect('ca')}>
                                        Canada
                                    </span>
                            </li>
                            <li className='location-list-item'>
                                    <span className='location-list-item-link' onClick={() => handelSelect('uk')}>
                                        United Kingdom
                                    </span>
                            </li>
                            <li className='location-list-item'>
                                    <span className='location-list-item-link' onClick={() => handelSelect('au')}>
                                        Australia
                                    </span>
                            </li>
                            <li className='location-list-item'>
                                    <span className='location-list-item-link' onClick={() => handelSelect('us')}>
                                        All other countries
                                    </span>
                            </li>
                        </ul>
                    </Fragment>
                </div>
            </Row>

            <CookieCondition/>
        </div>
    );
});
export default FloatPicWebLocation;
