import React, {Fragment, useState} from 'react';
import {History, Keyboard, Manipulation, Mousewheel, Navigation} from 'swiper/modules';

import {Swiper, SwiperSlide} from 'swiper/react';
import CartWrapper from '../../../../components/snippets/cart-wrapper';
import UseWindowDimensions from '../../../../components/snippets/use-window-dimensions';

import {useOrder} from '../../../../contexts/OrderContext';
import {usePage} from '../../../../contexts/PageContext';

import BorderSwitcher from '../../../../components/snippets/border-switcher';
import {
    getCookie,
    retailAPI,
} from '../../../../utils/common';
import TouchDeviceOption from "./TouchDeviceOption";
import NoTouchOption from "./NoTouchOption";
import {useNavigate} from "react-router-dom";

const ArtistSlides = ({loadNewProduct, setSwiper}) => {
    const [cartItemAdded, setCartItemAdded] = useState(null);
    const [currentSlideId, setCurrentSlideId] = useState(null);
    const {
        addToCart,
        priceDetails,
    } = useOrder();
    const [showBorderSwitchId, setShowBorderSwitchId] = useState(window.innerWidth < 600);
    const {height} = UseWindowDimensions();
    const navigate = useNavigate();

    const {
        showing,
        setIsMenubarOpen,
        setBorderPreference,
        setSwiperProduct,
        setTransitionStarted,
        filteredFloatPictures,
        setFilteredFloatPictures,
        currentSlider,
        setActiveMenuItem,
        idFromUrl,
        setCurrentSlide,
        isAddToCartOpen,
        setIsAddToCartOpen,
        setZoomedProductId,
        setZoomedProduct,
        setZoomed,
        isTouchDevice,
        setActiveMenu
    } = usePage();

    const handleFilterSwiperImageClick = (clickedItem) => {
        const clicked = filteredFloatPictures.find((item) => item.art_id === clickedItem.art_id);
        const tb = clicked.img.includes('-TB');
        const bw = clicked.img.includes('-BW');
        if (tb) {
            setBorderPreference('-BW');
        }
        if (bw) {
            setBorderPreference('-TB');
        }

        const pictures = filteredFloatPictures.map((item) => {
            item.img = tb ? item.imgBW : item.imgTB;
            return item;
        });
        setFilteredFloatPictures(pictures);
    };

    const onBeforeInit = async (swiper) => {
        if (!swiper) return;
        if (currentSlider === 'products') {
            setSwiperProduct(swiper);
            window.fu = swiper;
            await loadNewProduct(3);
        } else {
            setSwiper(swiper);
        }
        if (idFromUrl && swiper?.slides) {
            const slide = swiper.slides.find(item => item.getAttribute('data-history') === idFromUrl);
            if (slide) {
                const index = swiper.slides.indexOf(slide);
                swiper.slideTo(index, 0);
            }
        }

        swiper.update();
    }

    const changeDocumentTitle = (title) => {
        document.title = title || '';
    }

    const onSlideChange = async (swiper) => {
        setIsAddToCartOpen(false);
        setShowBorderSwitchId(null);
        if (!swiper) return;
        if (currentSlider === 'filteredProducts') {
            setCartItemAdded(null);
            const slide = swiper.slides[swiper.activeIndex];
            setCurrentSlide(slide.getAttribute('data-history'));
        }
        swiper.update();
    }

    const setCurrentState = (swiper) => {
        if (!swiper || !swiper.slides) return;
        const slide = swiper.slides[swiper.activeIndex];
        changeDocumentTitle(slide.getAttribute('data-title'))
        const artId = slide.getAttribute('data-history');
        setCurrentSlideId(artId)
    }

    return (
        <Fragment>
            <Swiper
                key={`${showing}`}
                modules={[
                    Navigation,
                    History,
                    Keyboard,
                    Manipulation,
                    Mousewheel,
                ]}
                history={{
                    key: 'product'
                }}
                onSlideNextTransitionStart={() => {
                    setTransitionStarted(Date.now());
                }}
                onBeforeInit={async (swiper) => {
                    if (swiper.slides) {
                        await onBeforeInit(swiper)
                    }
                }}
                onInit={(swiper) => {
                    setActiveMenu('artist')
                    if (!idFromUrl) {
                        const artId = swiper.slides?.[swiper.activeIndex].getAttribute('data-history');
                        navigate(`/product/${artId}`);
                    }
                    setCurrentState(swiper)
                }}
                onActiveIndexChange={setCurrentState}
                onBeforeSlideChangeStart={() => {
                    setShowBorderSwitchId(null);
                    setZoomed(false);
                    setZoomedProductId(null);
                    setZoomedProduct(null);
                }}
                onSlideChange={async (swiper) => {
                    await onSlideChange(swiper)
                }}
                speed={400}
                autoHeight={true}
                grabCursor={true}
                simulateTouch={true}
                mousewheel={true}
                keyboard={true}
                touchStartPreventDefault={false}
                cssMode={!!isTouchDevice}
            >
                {filteredFloatPictures.map((item) => (
                    <SwiperSlide
                        key={`products_${currentSlider}_${item.art_id}`}
                        data-history={item.art_id}
                        data-title={`${item.art_name} by ${item.artist_name} | FloatPic.com`}
                        className={`d-flex flex-column align-items-center justify-content-center w-100 [data-history="${item.art_id}"]`}
                    >
                        <div className={`swiper-slide-img-container `}
                             style={{height: height}}
                             data-msg={`${item.art_name} by ${item.artist_name}`}
                        >
                            <div>
                                {isTouchDevice ? (
                                    <TouchDeviceOption item={item} setShowBorderSwitchId={setShowBorderSwitchId}/>
                                ) : (
                                    <NoTouchOption item={item} setShowBorderSwitchId={setShowBorderSwitchId}/>
                                )}
                            </div>
                        </div>

                        <p className={`item-name ${isAddToCartOpen ? 'item-name-hidden' : 'item-name-visible'}`}
                           style={{display: 'hidden'}}
                           data-msg={`${item.art_name} by ${item.artist_name}`}
                        >
                            {item.art_name} by {item.artist_name}
                        </p>

                        {(item?.art_id === currentSlideId) ? (
                            <BorderSwitcher
                                isOpen={showBorderSwitchId === item?.art_id}
                                onSwitchBorder={() => {
                                    return handleFilterSwiperImageClick(item)
                                }}
                                onClickAddToOrder={(event) => {
                                    event.stopPropagation();
                                    setIsAddToCartOpen(true);
                                    setIsMenubarOpen(false);
                                    setCartItemAdded(item);
                                }}
                                isAddToCartOpen={isAddToCartOpen}
                                item={item}
                            />
                        ) : <></>}
                    </SwiperSlide>
                ))}
            </Swiper>

            {(currentSlideId === showBorderSwitchId) ? (
                <CartWrapper
                    isOpen={isAddToCartOpen}
                    onClick={async (e, itemSize, clearItemSize) => {
                        if (!itemSize) {
                            return alert('select a art type');
                        }
                        e.stopPropagation();
                        setIsAddToCartOpen(false);
                        let cartItem = {...cartItemAdded, itemSize};
                        const {S40_price, S55_price, S70_price} = priceDetails;
                        if (
                            itemSize === 'S40' ||
                            itemSize === 'TrueBorder 20' ||
                            itemSize === 'Black and White 20'
                        ) {
                            cartItem.price = S40_price;
                        } else if (
                            itemSize === 'S55' ||
                            itemSize === 'TrueBorder 40' ||
                            itemSize === 'Black and White 40'
                        ) {
                            cartItem.price = S55_price;
                        } else if (itemSize === 'S70') {
                            cartItem.price = S70_price;
                        }
                        setActiveMenuItem('3'); // setting order menu item open
                        setIsMenubarOpen(true);
                        addToCart(cartItem);
                        let post_data = {
                            eventType: 'add-to-cart',
                            eventTime: new Date().toISOString(),
                            userInfo: {
                                userAgent: navigator.userAgent,
                                ipAddress: '192.168.1.100',
                            },
                            visitorId: getCookie('_ga'),
                            productDetails: [
                                {
                                    product: {
                                        id: cartItem.art_id,
                                    },
                                    quantity: 1,
                                },
                            ],
                            attributes: {
                                global_website: {
                                    text: [window.location.host],
                                    indexable: true,
                                    searchable: true,
                                },
                            },
                        };
                        await retailAPI(post_data);
                        clearItemSize();
                    }}
                    onClose={() => setIsAddToCartOpen(false)}
                    btnText={'Add to Order'}
                />
            ) : <></>}
        </Fragment>
    );
};
export default ArtistSlides;
