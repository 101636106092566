import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useOnClickOutside} from '../../../hooks/useOnClickOutside';
import Button from '../../ui/button/Button';
import './CartWrapper.View.scss';
import {usePage} from '../../../contexts/PageContext';

const CartWrapper = ({onClick, btnText, onClose, isOpen}) => {
    const ref = useRef();
    const {borderPreference} = usePage();
    useOnClickOutside(ref, onClose);

    const [artType, setArtType] = useState('TrueBorder 20');
    const defaultType = (borderPreference === '-TB' || borderPreference === 'TB') ? 'TrueBorder 20' : 'Black and White 20';

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setArtType(defaultType)
    }, [borderPreference])

    return (
        <div className={`cart-wrapper ${isOpen ? 'active' : ''}`}>
            <div ref={ref} className={`open-cart-container ${isOpen ? 'open-cart-content' : ''}`}>
                <div className='cart-content-wrapper'>
                    <div className='select-container'>
                        <div className='select-wrapper'>
                            <label
                                htmlFor='artType'
                                className='custom-select-label'
                            >
                                Border and Size
                            </label>
                            <select
                                name='artType'
                                id='artType'
                                onChange={(e) => setArtType(e.target.value)}
                                className='form-select'
                                value={artType}
                            >
                                {borderPreference === '-TB' || borderPreference === 'TB' ? (
                                    <Fragment>
                                        <option selected value='TrueBorder 20'>TrueBorder™ 20</option>
                                        <option value='TrueBorder 40'>TrueBorder™ 40</option>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <option selected value='Black and White 20'>
                                            Black and White 20
                                        </option>
                                        <option value='Black and White 40'>
                                            Black and White 40
                                        </option>
                                    </Fragment>
                                )}
                            </select>
                        </div>
                    </div>

                    <Button
                        type='button'
                        text={btnText}
                        className='add-cart-btn'
                        onClick={(e) => onClick(e, artType, () => setArtType(defaultType))}
                    />
                </div>
            </div>
        </div>
    );
};

export default CartWrapper;
