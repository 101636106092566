import React, {memo, useEffect, useRef, useState} from 'react';
import DatalistInput, {includesValueFilter, useComboboxControls} from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import {usePage} from '../../../contexts/PageContext';
import authors from '../../../data/authors.json';
import './NowShowingTab.View.scss';
import {Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useOrder} from "../../../contexts/OrderContext";

const NowShowingTab = memo(() => {
    const {
        showingRef,
        setShowing,
        setActiveShopTab,
        floatPictures,
        borderPreference,
        filteredFloatPictures,
        setFilteredFloatPictures,
        transitionStarted,
        setFilterValue,
        filterValue,
        setIsMenubarOpen,
        isMenubarOpen,
        setCurrentSlider,
        comboboxValue,
        setComboboxValue,
        currentSlide,
        setCurrentSlide,
        allProductsclicked,
        setAllProductsclicked,
        setNoReload,
        setStopReload,
        reset,
        setActiveMenu,
        activeMenu
    } = usePage();

    const {totalCartItems} = useOrder();
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const {setValue, value, isExpanded, setIsExpanded} = useComboboxControls({
        initialValue: '',
        isExpanded: false,
    });

    const showTabItems = {
        recommendations: {
            id: 1,
            name: 'Continuous Recommendations',
            icon: '/images/menu-icon-1.png',
            active: true
        },
        artist: {
            id: 2,
            name: 'Artist Search',
            icon: '/images/menu-icon-2.png',
            active: true,
        },
        orders: {
            id: 3,
            name: 'Items in Your Order',
            icon: '/images/menu-icon-3.png',
            active: !!totalCartItems,
        },
        home: {
            id: 4,
            name: 'Homepage',
            icon: '/images/menu-icon-4.png',
            active: true
        },
    }

    useEffect(() => {
        if (activeMenu === 'recommendations') {
            setActiveShopTab('BROWSE');
            setTimeout(() => {
                setNoReload(true);
                setAllProductsclicked(true);
                setShowing((prev) => {
                    showingRef.current = prev;
                    return 'allProducts';
                });
                setFilterValue('');
                setFilteredData([]);
                setValue('');
                setCurrentSlider('products');
                if (allProductsclicked === 'false') {
                    floatPictures.length &&
                    setCurrentSlide(floatPictures[0].art_id);
                }
                if (currentSlide === null) {
                    if (floatPictures.length > 1) {
                        navigate(`/product/${floatPictures[0]?.art_id}`);
                        // document.title =
                        //     floatPictures[0]?.art_name +
                        //     ' by ' +
                        //     floatPictures[0]?.artist_name +
                        //     ' | FloatPic.com';
                    } else {
                        setStopReload(true);
                    }
                } else {
                    navigate(`/product/${currentSlide}`);
                }
                setIsMenubarOpen(false);
            }, 500);
        } else if (activeMenu === 'orders') {
            setShowing((prev) => {
                showingRef.current = prev;
                return 'orderList';
            });
            setCurrentSlider('orders');
            setIsMenubarOpen(false);
        }
        // else if (activeMenu === 'home') {
        //     reset();
        //     navigate('/');
        //     setIsMenubarOpen(false);
        // }
    }, [activeMenu]);


    /* eslint-disable react-hooks/exhaustive-deps */
    // Clear input if the menu is closed and the value does not match any artist
    useEffect(() => {
        if (!isMenubarOpen && value) {
            if (!authors.some(([artistName]) => artistName === value)) {
                setValue('');
            }
        }
    }, [isMenubarOpen, value]);

    // Retrieve stored value from sessionStorage on component mount
    useEffect(() => {
        if (comboboxValue) {
            setValue(comboboxValue);
        }
    }, [setValue]);

    // Synchronize filterValue with value
    useEffect(() => {
        setValue(filterValue);
    }, [filterValue]);

    // Persist value to sessionStorage when it changes
    useEffect(() => {
        setComboboxValue(value);
    }, [value]);

    const inputRef = useRef(null);
    const [totalProducts, setTotalProducts] = useState();

    // Set filterValue if the value matches an artist
    useEffect(() => {
        if (value && authors.some(([artistName]) => artistName === value)) {
            setFilterValue(value);
        }
    }, [value]);

    const fetchData = async (amount = 5, artistName) => {
        try {
            let response = await fetch(
                `https://floatpic-staging-rcg7asssaa-uc.a.run.app/load_new_products/?artist_name=${artistName}&no_of_items=${amount}`
            );
            let data = await response.json();
            let transformedData =
                data?.products_data.length &&
                data?.products_data.map(([art_id, art_name, artist_name, imgTB, imgBW]) => {
                    return {
                        art_id,
                        art_name,
                        artist_name,
                        imgTB,
                        imgBW,
                        img: borderPreference === '-TB' ? imgTB : imgBW,
                    };
                });
            setTotalProducts(data?.total_products);
            return transformedData;
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch data if the value matches an artist
    useEffect(() => {
        if (value && authors.some(([artistName]) => artistName === value)) {
            // setLoading(true);
            if (filteredFloatPictures.length && filteredFloatPictures[0].artist_name === value) {
                setCurrentSlider('filteredProducts');
                // setLoading(false);
                return;
            }
            (async () => {
                try {
                    const combinedData = await fetchData(undefined, value);
                    setFilteredFloatPictures(() => {
                        const updatedPictures = [...combinedData];
                        return Array.from(
                            new Map(updatedPictures.map((item) => [item?.art_id, item])).values()
                        );
                    });
                    setTimeout(() => {
                        navigate(`/product/${combinedData[0].art_id}`);
                        inputRef?.current?.querySelector('input').blur();
                    }, 110);
                    setIsMenubarOpen(false);
                } catch (error) {
                    console.log(error);
                } finally {
                    setCurrentSlider('filteredProducts');
                    // setLoading(false);
                }
            })();
        }
    }, [value]);

    useEffect(() => {
        if (filteredData.length >= 2) {
            const pictures = filteredData.map((item) => {
                item.img = borderPreference === '-BW' ? item.imgBW : item.imgTB;
                return item;
            });
            setFilteredFloatPictures((prev) => {
                const updatedPictures = [...prev, ...pictures];
                return Array.from(
                    new Map(updatedPictures.map((item) => [item?.art_id, item])).values()
                );
            });
            setFilteredData([]);
        }
    }, [filteredData]);

    // Fetch more data if transitionStarted or value changes
    useEffect(() => {
        if (value && authors.some(([artistName]) => artistName === value)) {
            (async () => {
                try {
                    if (filteredFloatPictures.length < totalProducts) {
                        const combinedData = await fetchData(3, value);
                        setFilteredData((prev) => [...prev, ...combinedData]);
                    }
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [transitionStarted, value, filteredFloatPictures.length, totalProducts]);

    return (
        <div className='now-showing-tab-container'>
            {/* eslint-disable-next-line array-callback-return */}
            {Object.keys(showTabItems).map((key, index) => {
                if (showTabItems[key].active) {
                    return (
                        <div key={index} className="now-showing-item">
                            <Form.Check className="now-showing-item__check"
                                        type="radio" name="showing"
                                        id={`showing-${showTabItems[key].id}`}>
                                <Form.Check.Input
                                    type="radio"
                                    checked={key === activeMenu} isValid
                                    onChange={(e) => {
                                        if (e.target.id === `showing-${showTabItems[key].id}` && e.target.checked) {
                                            setActiveMenu(key)
                                        }
                                    }}/>
                                <Form.Check.Label>
                                    <img src={showTabItems[key].icon}
                                         alt={showTabItems[key].name}
                                         loading="lazy"
                                         className="now-showing-item__icon"/>
                                    {showTabItems[key].name}
                                </Form.Check.Label>
                            </Form.Check>

                            {(showTabItems[key].id === 2 && showTabItems[activeMenu].id === 2) ? (
                                <div className="relative py-2">
                                    <div className='input-container'>
                                        <DatalistInput
                                            ref={inputRef}
                                            value={value}
                                            onClick={(e) => e.preventDefault()}
                                            isExpanded={isExpanded}
                                            setIsExpanded={setIsExpanded}
                                            filters={[(items) => (value ? items : []), includesValueFilter]}
                                            setValue={setValue}
                                            onSelect={(item) => {
                                                setIsMenubarOpen(false);
                                                setShowing((prev) => {
                                                    showingRef.current = prev;
                                                    return 'allProducts';
                                                });
                                            }}
                                            autoFocus={Boolean(value)}
                                            onBlur={(item) => {
                                                if (!item.target.value) {
                                                    setCurrentSlider('products');
                                                    setFilterValue('');
                                                    setFilteredData([]);
                                                }
                                            }}
                                            items={authors.map((item, index) => ({
                                                id: index,
                                                value: item[0],
                                            }))}
                                            label=""/>
                                        <label className={value && 'filled'}>Type artist name</label>
                                        {value && (
                                            <button
                                                className='remove-filter-btn'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setValue('');
                                                    inputRef?.current?.querySelector('input').focus();
                                                }}
                                            >
                                                X
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ) : <></>}
                        </div>
                    )
                }
            })}
        </div>
    );
});

export default NowShowingTab;
